var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-modal",
        {
          attrs: {
            title: _vm.$t("settings.analytics.switchDateModalTitle"),
            "ok-only": "",
            "ok-variant": "primary",
            "ok-title": _vm.$t("buttons.ok"),
            centered: ""
          },
          model: {
            value: _vm.showSwitchDateModal,
            callback: function($$v) {
              _vm.showSwitchDateModal = $$v
            },
            expression: "showSwitchDateModal"
          }
        },
        [
          _c(
            "div",
            { staticClass: "text-center p-4" },
            [
              _c("p", {
                domProps: {
                  innerHTML: _vm._s(
                    _vm.$t("settings.analytics.switchDateModalText", {
                      switchDate: _vm.switchDateFormat
                    })
                  )
                }
              }),
              _c(
                "b-button",
                {
                  staticClass: "mt-3",
                  attrs: { variant: "link" },
                  on: { click: _vm.openEmail }
                },
                [
                  _vm._v(
                    " " +
                      _vm._s(_vm.$t("settings.analytics.contactSupport")) +
                      " "
                  )
                ]
              )
            ],
            1
          )
        ]
      ),
      _c(
        "b-row",
        { staticClass: "row mb-4 mt-5" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "10" } }, [
            _c(
              "div",
              { staticClass: "d-flex justify-content-between" },
              [
                _c("h3", [_vm._v(_vm._s(_vm.$t("settings.analytics.name")))]),
                _c("date-range-picker", {
                  ref: "picker",
                  attrs: {
                    opens: "left",
                    "locale-data": _vm.localeData,
                    minDate: _vm.minDate,
                    singleDatePicker: false,
                    showWeekNumbers: true,
                    showDropdowns: true,
                    autoApply: false
                  },
                  on: { update: _vm.updateValues },
                  scopedSlots: _vm._u([
                    {
                      key: "input",
                      fn: function(picker) {
                        return [
                          _c(
                            "div",
                            { staticStyle: { "min-width": "350px" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("inputDate")(picker.startDate)
                                  ) +
                                  " - " +
                                  _vm._s(_vm._f("inputDate")(picker.endDate)) +
                                  " "
                              ),
                              _c("b-icon", {
                                staticClass: "pl-1 dropdown-icon",
                                attrs: {
                                  icon: "caret-down-fill",
                                  "aria-hidden": "true"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.dateRange,
                    callback: function($$v) {
                      _vm.dateRange = $$v
                    },
                    expression: "dateRange"
                  }
                })
              ],
              1
            ),
            _c("hr"),
            _c("p", {
              domProps: {
                innerHTML: _vm._s(_vm.$t("settings.analytics.descPirsch"))
              }
            })
          ])
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "row mb-4 mt-4" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
            [
              _vm.queryErrors.globalStats
                ? _c(
                    "b-row",
                    { staticClass: "global-stats mb-5" },
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c("h4", [_vm._v("Global stats")]),
                        _c("div", { staticClass: "query-error" }, [
                          _vm._v(
                            " " +
                              _vm._s(
                                _vm.$t(
                                  "settings.analytics.globalStatsQueryError"
                                )
                              ) +
                              " "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm.globalStats
                ? _c(
                    "b-row",
                    { staticClass: "row global-stats mb-5" },
                    [
                      _c("b-col", { attrs: { cols: "12", sm: "3" } }, [
                        _c(
                          "h5",
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("settings.analytics.visitors")) +
                                " "
                            ),
                            _c("b-icon", {
                              staticStyle: { "margin-left": "3px" },
                              attrs: {
                                id: "tooltip-global-visitors",
                                icon: "question-circle",
                                "aria-hidden": "true"
                              }
                            }),
                            _c(
                              "b-tooltip",
                              { attrs: { target: "tooltip-global-visitors" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "settings.analytics.pirschVisitorsTooltip"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("div", [_vm._v(_vm._s(_vm.globalStats.visitors))])
                      ]),
                      _c("b-col", { attrs: { cols: "12", sm: "3" } }, [
                        _c(
                          "h5",
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("settings.analytics.views")) +
                                " "
                            ),
                            _c("b-icon", {
                              staticStyle: { "margin-left": "3px" },
                              attrs: {
                                id: "tooltip-global-views",
                                icon: "question-circle",
                                "aria-hidden": "true"
                              }
                            }),
                            _c(
                              "b-tooltip",
                              { attrs: { target: "tooltip-global-views" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("settings.analytics.viewsTooltip")
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("div", [_vm._v(_vm._s(_vm.globalStats.views))])
                      ]),
                      _c("b-col", { attrs: { cols: "12", sm: "3" } }, [
                        _c(
                          "h5",
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("settings.analytics.avgTime")) +
                                " "
                            ),
                            _c("b-icon", {
                              staticStyle: { "margin-left": "3px" },
                              attrs: {
                                id: "tooltip-global-avg-time",
                                icon: "question-circle",
                                "aria-hidden": "true"
                              }
                            }),
                            _c(
                              "b-tooltip",
                              { attrs: { target: "tooltip-global-avg-time" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "settings.analytics.pirschAvgPageTimeTooltip"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("div", [
                          _vm._v(
                            _vm._s(
                              _vm._f("timeString")(_vm.globalStats.avgTime)
                            )
                          )
                        ])
                      ]),
                      _c("b-col", { attrs: { cols: "12", sm: "3" } }, [
                        _c(
                          "h5",
                          { attrs: { id: "global-bounce-rate" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("settings.analytics.bounceRate")
                                ) +
                                " "
                            ),
                            _c("b-icon", {
                              staticStyle: { "margin-left": "3px" },
                              attrs: {
                                id: "tooltip-global-bounce-rate",
                                icon: "question-circle",
                                "aria-hidden": "true"
                              }
                            }),
                            _c(
                              "b-tooltip",
                              {
                                attrs: { target: "tooltip-global-bounce-rate" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "settings.analytics.bounceRateTooltip"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("div", [_vm._v(_vm._s(_vm.globalStats.bounceRate))])
                      ])
                    ],
                    1
                  )
                : _vm._e()
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-5" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
            [
              _c("h4", [
                _vm._v(_vm._s(_vm.$t("settings.analytics.readsPerDay")))
              ]),
              _vm.queryErrors.readsPerDayObject
                ? _c("div", { staticClass: "query-error" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("settings.analytics.readsPerDayQueryError")
                        ) +
                        " "
                    )
                  ])
                : _vm.readsPerDayObject
                ? _c("LineChart", {
                    key: _vm.readsPerDayKey,
                    attrs: {
                      "reads-per-day-object": _vm.readsPerDayObject,
                      dateRange: _vm.dateRange
                    }
                  })
                : _c("p", [
                    _vm._v(_vm._s(_vm.$t("settings.analytics.noReadsPerDay")))
                  ])
            ],
            1
          )
        ],
        1
      ),
      _vm.queryErrors.projectOrFolders
        ? _c(
            "b-row",
            { staticClass: "mb-5" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                [
                  _c("h4", [
                    _vm._v(
                      _vm._s(_vm.$t("settings.analytics.projectsOrFolders"))
                    )
                  ]),
                  _c("div", { staticClass: "query-error" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t(
                            "settings.analytics.projectOrFoldersQueryError"
                          )
                        ) +
                        " "
                    )
                  ])
                ]
              )
            ],
            1
          )
        : _vm.itemType === "folder" && _vm.projectOrFolders.length
        ? _c(
            "b-row",
            { staticClass: "mb-5" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                [
                  _c("h4", [
                    _vm._v(
                      _vm._s(_vm.$t("settings.analytics.projectsOrFolders"))
                    )
                  ]),
                  _c("b-table", {
                    key: _vm.folderOrProjectTableKey,
                    attrs: {
                      fields: _vm.projectAndFolderFields,
                      items: _vm.projectOrFolders,
                      borderless: "",
                      dark: "",
                      fixed: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "table-colgroup",
                        fn: function(scope) {
                          return _vm._l(scope.fields, function(field) {
                            return _c("col", {
                              key: field.key,
                              style: { width: field.width }
                            })
                          })
                        }
                      },
                      {
                        key: "cell(image)",
                        fn: function(data) {
                          return [
                            data.item.type === "folder"
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "cell-folder cell-background-image d-flex justify-content-center align-items-center"
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        icon: "folder",
                                        "aria-hidden": "true",
                                        scale: "1"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : data.item.type === "project" && !data.item.image
                              ? _c(
                                  "div",
                                  {
                                    staticClass:
                                      "cell-background-image d-flex justify-content-center align-items-center"
                                  },
                                  [
                                    _c("b-icon", {
                                      attrs: {
                                        icon: "files",
                                        "aria-hidden": "true",
                                        scale: "1"
                                      }
                                    })
                                  ],
                                  1
                                )
                              : data.item.type === "project" && data.item.image
                              ? _c("div", {
                                  staticClass: "cell-background-image",
                                  style: `background-image: ${data.item.image}`
                                })
                              : _vm._e()
                          ]
                        }
                      },
                      {
                        key: "cell(timeOnPage)",
                        fn: function(data) {
                          return [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm._f("timeString")(data.item.timeOnPage)
                                ) +
                                " "
                            )
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.queryErrors.pages
        ? _c(
            "b-row",
            { staticClass: "mb-5" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "5" } },
                [
                  _c("h4", [
                    _vm._v(_vm._s(_vm.$t("settings.analytics.pages")))
                  ]),
                  _c("div", { staticClass: "query-error" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("settings.analytics.pagesQueryError")) +
                        " "
                    )
                  ])
                ]
              )
            ],
            1
          )
        : _vm.pages && _vm.pages.length
        ? _c(
            "b-row",
            { staticClass: "mb-5" },
            [
              _c(
                "b-col",
                { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
                [
                  _c("h4", [
                    _vm._v(_vm._s(_vm.$t("settings.analytics.pages")))
                  ]),
                  _c("b-table", {
                    key: _vm.pageTableKey,
                    attrs: {
                      fields: _vm.fields,
                      items: _vm.pages,
                      borderless: "",
                      dark: "",
                      fixed: ""
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "table-colgroup",
                        fn: function(scope) {
                          return _vm._l(scope.fields, function(field) {
                            return _c("col", {
                              key: field.key,
                              style: { width: field.width }
                            })
                          })
                        }
                      },
                      {
                        key: "cell(image)",
                        fn: function(data) {
                          return [
                            _c("div", {
                              staticClass: "cell-background-image",
                              style: {
                                backgroundImage: data.item.imageObject.imageUrl,
                                backgroundPosition:
                                  data.item.imageObject.focalpoint
                              }
                            })
                          ]
                        }
                      },
                      {
                        key: "cell(name)",
                        fn: function(data) {
                          return [
                            _c("div", { staticClass: "name-cell" }, [
                              _c("div", { staticClass: "name-wrapper" }, [
                                _vm._v(" " + _vm._s(data.item.name) + " ")
                              ])
                            ])
                          ]
                        }
                      },
                      {
                        key: "cell(viewers)",
                        fn: function(data) {
                          return [
                            _c("div", { staticClass: "viewers-cell" }, [
                              _c("div", { staticClass: "viewers-wrapper" }, [
                                _vm._v(" " + _vm._s(data.item.viewers) + " ")
                              ])
                            ])
                          ]
                        }
                      },
                      {
                        key: "cell(views)",
                        fn: function(data) {
                          return [
                            _c("div", { staticClass: "views-cell" }, [
                              _c("div", { staticClass: "views-wrapper" }, [
                                _vm._v(" " + _vm._s(data.item.views) + " ")
                              ])
                            ])
                          ]
                        }
                      },
                      {
                        key: "cell(timeOnPage)",
                        fn: function(data) {
                          return [
                            _c(
                              "div",
                              { staticClass: "avg-reading-time-cell" },
                              [
                                _c(
                                  "div",
                                  { staticClass: "avg-reading-time-wrapper" },
                                  [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("timeString")(
                                            data.item.timeOnPage
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                )
                              ]
                            )
                          ]
                        }
                      },
                      {
                        key: "cell(bounceRate)",
                        fn: function(data) {
                          return [
                            _c("div", { staticClass: "bounce-rate-cell" }, [
                              _c(
                                "div",
                                { staticClass: "bounce-rate-wrapper" },
                                [
                                  _vm._v(
                                    " " + _vm._s(data.item.bounceRate) + " "
                                  )
                                ]
                              )
                            ])
                          ]
                        }
                      }
                    ])
                  })
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _c(
        "b-row",
        { staticClass: "mb-5" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
            [
              _c("h4", { staticClass: "mb-4" }, [
                _vm._v(_vm._s(_vm.$t("settings.analytics.events")))
              ]),
              _vm.queryErrors.events
                ? _c("div", { staticClass: "query-error" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("settings.analytics.eventsQueryError")) +
                        " "
                    )
                  ])
                : _vm.groupedEvents && Object.keys(_vm.groupedEvents).length
                ? _vm._l(_vm.groupedEvents, function(events, category) {
                    return _c(
                      "b-card",
                      {
                        key: category,
                        staticClass: "mb-2",
                        attrs: { "no-body": "" }
                      },
                      [
                        _c(
                          "b-card-header",
                          { attrs: { "header-tag": "header", role: "tab" } },
                          [
                            _c(
                              "b-button",
                              {
                                directives: [
                                  {
                                    name: "b-toggle",
                                    rawName: "v-b-toggle",
                                    value: category.replace(/\s+/g, ""),
                                    expression: "category.replace(/\\s+/g, '')"
                                  }
                                ],
                                staticClass: "text-left event-category",
                                attrs: { block: "", variant: "dark" }
                              },
                              [
                                _c(
                                  "div",
                                  {
                                    staticClass:
                                      "d-flex justify-content-between align-items-center"
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "d-flex align-items-center"
                                      },
                                      [
                                        _c("b-icon", {
                                          staticClass: "chevron-icon",
                                          attrs: {
                                            icon: "chevron-down",
                                            "aria-hidden": "true",
                                            scale: "1"
                                          }
                                        }),
                                        _c("span", { staticClass: "ml-2" }, [
                                          _vm._v(_vm._s(category))
                                        ])
                                      ],
                                      1
                                    ),
                                    _c(
                                      "div",
                                      { staticClass: "event-summary" },
                                      [
                                        _c("span", { staticClass: "mr-4" }, [
                                          _vm._v(
                                            _vm._s(
                                              _vm.getTotalVisitors(events)
                                            ) +
                                              " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "settings.analytics.visitors"
                                                )
                                              )
                                          )
                                        ]),
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(_vm.getTotalCount(events)) +
                                              " " +
                                              _vm._s(
                                                _vm.$t(
                                                  "settings.analytics.events"
                                                )
                                              )
                                          )
                                        ])
                                      ]
                                    )
                                  ]
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c(
                          "b-collapse",
                          {
                            attrs: {
                              id: category.replace(/\s+/g, ""),
                              accordion: "events-accordion",
                              role: "tabpanel"
                            }
                          },
                          [
                            _c(
                              "b-card-body",
                              { staticClass: "p-0" },
                              [
                                _c("b-table", {
                                  key: category.replace(/\s+/g, ""),
                                  staticClass: "mb-0",
                                  attrs: {
                                    items: events,
                                    fields: _vm.eventFields,
                                    dark: "",
                                    borderless: "",
                                    fixed: ""
                                  },
                                  scopedSlots: _vm._u(
                                    [
                                      {
                                        key: "table-colgroup",
                                        fn: function(scope) {
                                          return _vm._l(scope.fields, function(
                                            field
                                          ) {
                                            return _c("col", {
                                              key: field.key,
                                              style: { width: field.width }
                                            })
                                          })
                                        }
                                      },
                                      {
                                        key: "cell(label)",
                                        fn: function(data) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  (
                                                    data.item.meta.label || "-"
                                                  ).trim()
                                                ) +
                                                " "
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "cell(action)",
                                        fn: function(data) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(data.item.meta.action) +
                                                " "
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "cell(visitors)",
                                        fn: function(data) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(data.item.visitors) +
                                                " "
                                            )
                                          ]
                                        }
                                      },
                                      {
                                        key: "cell(count)",
                                        fn: function(data) {
                                          return [
                                            _vm._v(
                                              " " +
                                                _vm._s(data.item.count) +
                                                " "
                                            )
                                          ]
                                        }
                                      }
                                    ],
                                    null,
                                    true
                                  )
                                })
                              ],
                              1
                            )
                          ],
                          1
                        )
                      ],
                      1
                    )
                  })
                : _c("p", [
                    _vm._v(_vm._s(_vm.$t("settings.analytics.noEvents")))
                  ])
            ],
            2
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-5" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
            [
              _c("h4", [
                _vm._v(_vm._s(_vm.$t("settings.analytics.referrers")))
              ]),
              _vm.queryErrors.referrers
                ? _c("div", { staticClass: "query-error" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("settings.analytics.referrersQueryError")
                        ) +
                        " "
                    )
                  ])
                : _vm.referrers.length
                ? _c("b-table", {
                    staticClass: "referrers-table",
                    attrs: {
                      items: _vm.referrers,
                      fields: _vm.referrerFields,
                      hover: "",
                      dark: "",
                      "sort-by": "visitors",
                      "sort-desc": ""
                    }
                  })
                : _c("p", [
                    _vm._v(_vm._s(_vm.$t("settings.analytics.noReferrers")))
                  ])
            ],
            1
          )
        ],
        1
      ),
      _c(
        "b-row",
        { staticClass: "mb-5" },
        [
          _c(
            "b-col",
            { attrs: { cols: "12", "offset-sm": "1", sm: "5" } },
            [
              _c("h4", [
                _vm._v(_vm._s(_vm.$t("settings.analytics.deviceType")))
              ]),
              _vm.queryErrors.devices
                ? _c("div", { staticClass: "query-error" }, [
                    _vm._v(
                      " " +
                        _vm._s(_vm.$t("settings.analytics.devicesQueryError")) +
                        " "
                    )
                  ])
                : _vm.devices.length
                ? _c("b-table", {
                    staticClass: "device-table",
                    attrs: {
                      items: _vm.devices,
                      fields: _vm.deviceFields,
                      hover: "",
                      dark: "",
                      "sort-by": "visitors",
                      "sort-desc": ""
                    }
                  })
                : _c("p", [
                    _vm._v(_vm._s(_vm.$t("settings.analytics.noDevices")))
                  ])
            ],
            1
          ),
          _c(
            "b-col",
            { attrs: { cols: "12", sm: "5" } },
            [
              _c("h4", [
                _vm._v(_vm._s(_vm.$t("settings.analytics.countries")))
              ]),
              _vm.queryErrors.countries
                ? _c("div", { staticClass: "query-error" }, [
                    _vm._v(
                      " " +
                        _vm._s(
                          _vm.$t("settings.analytics.countriesQueryError")
                        ) +
                        " "
                    )
                  ])
                : _vm.countries.length
                ? _c("b-table", {
                    staticClass: "country-table",
                    attrs: {
                      items: _vm.countries,
                      fields: _vm.countryFields,
                      hover: "",
                      dark: "",
                      "sort-by": "readers",
                      "sort-desc": ""
                    }
                  })
                : _c("p", [
                    _vm._v(_vm._s(_vm.$t("settings.analytics.noCountries")))
                  ])
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }