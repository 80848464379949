<template>
  <div>
    <b-row class="row mb-4 mt-5">
      <b-col cols="12" offset-sm="1" sm="10">
        <div class="d-flex justify-content-between">
          <h3>{{ $t('settings.analytics.name') }}</h3>
          <div v-if="showDomainSelect" class="domain-select">
            <b-form-select
              v-model="selectedDomain"
              :options="domainSelectOptions"
              @change="getDataForSelectedDomain"
            >
            </b-form-select>
          </div>
          <date-range-picker
            ref="picker"
            opens="left"
            :locale-data="localeData"
            :minDate="minDate"
            :singleDatePicker="false"
            :showWeekNumbers="true"
            :showDropdowns="true"
            :autoApply="false"
            v-model="dateRange"
            @update="updateValues"
          >
          <template v-slot:input="picker">
              <div style="min-width: 350px;">
                {{ picker.startDate | inputDate }} - {{ picker.endDate | inputDate }}
                <b-icon class="pl-1 dropdown-icon" icon="caret-down-fill" aria-hidden="true"></b-icon>
              </div>
            </template>
          </date-range-picker>
        </div>
        <hr>
        <p class="mb-5" v-html="$t('settings.analytics.desc')"></p>
      </b-col>
    </b-row>
    <b-row>
      <b-col cols="12" offset-sm="1" sm="10">
        <b-row v-if="queryErrors.globalStats" class="global-stats mb-5">
          <b-col cols="12">
            <h4>Global stats</h4>
            <div class="query-error">
              {{ $t('settings.analytics.queryError') }}
            </div>
          </b-col>
        </b-row>
        <b-row v-else-if="globalStats" class="global-stats mb-5">
          <b-col cols="12" sm="3">
            <h5>
              {{ $t('settings.analytics.visitors') }}
              <b-icon
                style="margin-left: 3px;"
                id="tooltip-global-visitors"
                icon="question-circle"
                aria-hidden="true">
              </b-icon>
              <b-tooltip
                target="tooltip-global-visitors"
              >
                {{ $t('settings.analytics.visitorsTooltip') }}
              </b-tooltip>
            </h5>
            <div>{{ globalStats.visitors }}</div>
          </b-col>
          <b-col cols="12" sm="3">
            <h5>
              {{ $t('settings.analytics.views') }}
              <b-icon
                style="margin-left: 3px;"
                id="tooltip-global-views"
                icon="question-circle"
                aria-hidden="true">
              </b-icon>
              <b-tooltip
                target="tooltip-global-views"
              >
                {{ $t('settings.analytics.viewsTooltip') }}
              </b-tooltip>
            </h5>
            <div>{{ globalStats.views }}</div>
          </b-col>
          <b-col cols="12" sm="3">
            <h5>
              {{ $t('settings.analytics.avgTime') }}
              <b-icon
                style="margin-left: 3px;"
                id="tooltip-global-avg-time"
                icon="question-circle"
                aria-hidden="true">
              </b-icon>
              <b-tooltip
                target="tooltip-global-avg-time"
              >
                {{ $t('settings.analytics.avgTimeTooltip') }}
              </b-tooltip>
            </h5>
            <div>{{ globalStats.avgTime | timeSting }}</div>
          </b-col>
          <b-col cols="12" sm="3">
            <h5 id="global-bounce-rate">
              {{ $t('settings.analytics.bounceRate') }}
              <b-icon
                style="margin-left: 3px;"
                id="tooltip-global-bounce-rate"
                icon="question-circle"
                aria-hidden="true">
              </b-icon>
              <b-tooltip
                target="tooltip-global-bounce-rate"
              >
                {{ $t('settings.analytics.bounceRateTooltip') }}
              </b-tooltip>
            </h5>
            <div>{{ globalStats.bounceRate }}</div>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <h4>{{ $t('settings.analytics.readsPerDay') }}</h4>
            <div v-if="queryErrors.readsPerDayObject" class="query-error">
              {{ $t('settings.analytics.queryError') }}
            </div>
            <LineChart
              v-else-if="readsPerDayObject"
              :key="key"
              :reads-per-day-object="readsPerDayObject"
              :dateRange="dateRange"
            />
          </b-col>
        </b-row>
        <b-row v-if="queryErrors.projectOrFolders" class="mb-5">
          <b-col cols="12">
            <h4>{{ $t('settings.analytics.projectsOrFolders') }}</h4>
            <div class="query-error">
              {{ $t('settings.analytics.queryError') }}
            </div>
          </b-col>
        </b-row>
        <b-row class="mb-5" v-else-if="itemType !== 'project' && projectOrFolders.length">
          <b-col>
            <h4>{{ $t('settings.analytics.projectsOrFolders') }}</h4>
            <b-table
              :key="folderOrProjectTableKey"
              :fields="projectAndFolderFields"
              :items="projectOrFolders"
              borderless
              dark
              fixed
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template>
              <template #head(entries)>
                <div class="text-nowrap" id="tooltip-folder-project-entries">
                  {{ $t('settings.analytics.visitors') }}
                  <b-tooltip
                    target="tooltip-folder-project-entries"
                  >
                    {{ $t('settings.analytics.visitorsTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #head(viewers)>
                <div class="text-nowrap" id="tooltip-folder-project-viewers">
                  {{ $t('settings.analytics.viewers') }}
                  <b-tooltip
                    target="tooltip-folder-project-viewers"
                  >
                    {{ $t('settings.analytics.viewersTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #head(views)>
                <div class="text-nowrap" id="tooltip-folder-project-views">
                  {{ $t('settings.analytics.views') }}
                  <b-tooltip
                    target="tooltip-folder-project-views"
                  >
                    {{ $t('settings.analytics.viewsTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #head(avgReadingTime)>
                <div class="text-nowrap" id="tooltip-folder-project-avgReadingTime">
                  {{ $t('settings.analytics.avgTime') }}
                  <b-tooltip
                    target="tooltip-folder-project-avgReadingTime"
                  >
                    {{ $t('settings.analytics.avgTimeTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #head(bounceRate)>
                <div class="text-nowrap" id="tooltip-folder-project-bounceRate">
                  {{ $t('settings.analytics.bounceRate') }}
                  <b-tooltip
                    target="tooltip-folder-project-bounceRate"
                  >
                    {{ $t('settings.analytics.bounceRateTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #cell(image)="data">
                <div
                  v-if="data.item.type === 'folder'"
                  class="cell-folder cell-background-image d-flex
            justify-content-center align-items-center"
                >
                  <b-icon
                    icon="folder"
                    aria-hidden="true"
                    scale="1"
                  ></b-icon>
                </div>
                <div
                  v-if="data.item.type === 'project' && !data.item.image"
                  class="cell-background-image d-flex justify-content-center align-items-center"
                >
                  <b-icon
                    icon="files"
                    aria-hidden="true"
                    scale="1"
                  ></b-icon>
                </div>
                <div
                  v-if="data.item.type === 'project' && data.item.image"
                  class="cell-background-image"
                  :style="`background-image: url(${data.item.image})`"
                >
                </div>
              </template>
              <template #cell(avgReadingTime)="data">
                {{ data.item.avgReadingTime | timeSting }}
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row v-if="queryErrors.pages" class="mb-5">
          <b-col cols="12">
            <h4>{{ $t('settings.analytics.pages') }}</h4>
            <div class="query-error">
              {{ $t('settings.analytics.queryError') }}
            </div>
          </b-col>
        </b-row>
        <b-row v-else-if="pages && pages.length" class="mb-5">
          <b-col>
            <h4>{{ $t('settings.analytics.pages') }}</h4>
            <b-table
              :key="pageTableKey"
              :fields="fields"
              :items="pages"
              borderless
              dark
              fixed
            >
              <template #table-colgroup="scope">
                <col
                  v-for="field in scope.fields"
                  :key="field.key"
                  :style="{ width: field.width }"
                >
              </template>
              <template #head(entries)>
                <div class="text-nowrap" id="tooltip-page-entries">
                  {{ $t('settings.analytics.entries') }}
                  <b-tooltip
                    target="tooltip-page-entries"
                  >
                    {{ $t('settings.analytics.entriesTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #head(viewers)>
                <div class="text-nowrap" id="tooltip-page-viewers">
                  {{ $t('settings.analytics.pageViewers') }}
                  <b-tooltip
                    target="tooltip-page-viewers"
                  >
                    {{ $t('settings.analytics.viewersTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #head(views)>
                <div class="text-nowrap" id="tooltip-page-views">
                  {{ $t('settings.analytics.views') }}
                  <b-tooltip
                    target="tooltip-page-views"
                  >
                    {{ $t('settings.analytics.viewsTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #head(avgReadingTime)>
                <div class="text-nowrap" id="tooltip-page-avgReadingTime">
                  {{ $t('settings.analytics.avgTime') }}
                  <b-tooltip
                    target="tooltip-page-avgReadingTime"
                  >
                    {{ $t('settings.analytics.avgTimeTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #head(bounceRate)>
                <div class="text-nowrap" id="tooltip-page-bounceRate">
                  {{ $t('settings.analytics.bounceRate') }}
                  <b-tooltip
                    target="tooltip-page-bounceRate"
                  >
                    {{ $t('settings.analytics.bounceRateTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #cell(image)="data">
                <div
                  class="cell-background-image"
                  :style="{
                    backgroundImage: data.item.imageObject.imageUrl,
                    backgroundPosition: data.item.imageObject.focalpoint,
                }"
                ></div>
              </template>
              <template #cell(avgReadingTime)="data">
                {{ data.item.avgReadingTime | timeSting }}
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col>
            <h4>{{ $t('settings.analytics.referrers') }}</h4>
            <div v-if="queryErrors.referrers" class="query-error">
              {{ $t('settings.analytics.queryError') }}
            </div>
            <b-table
              v-else
              :fields="fieldReferrers"
              :items="referrers"
              borderless
              show-empty
              dark
              fixed
            >
              <template #empty>
                <div>{{ $t('settings.analytics.noResult') }}</div>
              </template>
              <template #head(visitors)>
                <div class="text-nowrap" id="tooltip-referrers-visitors">
                  {{ $t('settings.analytics.visitors') }}
                  <b-tooltip
                    target="tooltip-referrers-visitors"
                  >
                    {{ $t('settings.analytics.visitorsReferrerTooltip') }}
                  </b-tooltip>
                </div>
              </template>
              <template #head(views)>
                <div class="text-nowrap" id="tooltip-referrers-views">
                  {{ $t('settings.analytics.views') }}
                  <b-tooltip
                    target="tooltip-referrers-views"
                  >
                    {{ $t('settings.analytics.viewsReferrerTooltip') }}
                  </b-tooltip>
                </div>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="mb-5">
          <b-col cols="6">
            <h4>{{ $t('settings.analytics.deviceType') }}</h4>
            <div v-if="queryErrors.deviceTypes" class="query-error">
              {{ $t('settings.analytics.queryError') }}
            </div>
            <b-table
              v-else
              :fields="fieldDeviceType"
              :items="deviceTypes"
              borderless
              show-empty
              dark
              fixed
            >
              <template #empty>
                <div>{{ $t('settings.analytics.noResult') }}</div>
              </template>
            </b-table>
          </b-col>
          <b-col cols="6">
            <h4>{{ $t('settings.analytics.countries') }}</h4>
            <div v-if="queryErrors.countries" class="query-error">
              {{ $t('settings.analytics.queryError') }}
            </div>
            <b-table
              v-else
              :fields="fieldCountries"
              :items="countries"
              borderless
              show-empty
              dark
              fixed
            >
              <template #empty>
                <div>{{ $t('settings.analytics.noResult') }}</div>
              </template>
            </b-table>
          </b-col>
        </b-row>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import SetLoadingIndicator from '@/graphQlQueries/mutations/setLoadingIndicatorInCache';
import GetAllChildPageIdsOfFolder from '@/graphQlQueries/queries/getAllChildPageIdsOfFolder';
import GetAllChildPageIdsOfProject from '@/graphQlQueries/queries/getAllChildPageIdsOfProject';
import GetAllFoldersAndProjectsByFolderId from '@/graphQlQueries/queries/getAllFoldersAndProjectsByFolderId';
import GetFathomData from '@/graphQlQueries/queries/getFathomData';
// import axios from 'axios';
import DateRangePicker from 'vue2-daterange-picker';
import LineChart from '@/components/settings/analytics/LineChart.vue';
import 'vue2-daterange-picker/dist/vue2-daterange-picker.css';

export default {
  name: 'UsefathomAnalyticsDashboard',
  components: { DateRangePicker, LineChart },
  props: {
    slugPathFromUrl: {
      type: String,
      default: null,
    },
    groupDomain: {
      type: String,
      default: null,
    },
    groupSlug: {
      type: String,
      default: null,
    },
    itemId: {
      type: Number,
      default: null,
    },
    itemType: {
      type: String,
      default: null,
    },
    showReadersInTable: {
      type: Boolean,
      default: false,
    },
    isSysAdmin: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    showLoadingIndicator() {
      return this.$apollo.loading;
    },
    domainSelectOptions() {
      return [this.groupDomain, this.defaultWebmagDomain];
    },
  },
  created() {
    this.$apollo.mutate({
      mutation: SetLoadingIndicator,
      variables: {
        isIndicatorLoading: true,
      },
    });
    this.selectedDomain = this.groupDomain;
    this.defaultWebmagDomain = `${process.env.VUE_APP_DOMAIN.replace('[group_slug]', this.groupSlug)}`;
    if (this.groupDomain.trim() !== this.defaultWebmagDomain.trim()) {
      this.showDomainSelect = true;
    }
    this.path = `/${this.$route.params.parametersOfUrl}/`;
    this.path = (this.$route.params.parametersOfUrl)
      ? `/${this.$route.params.parametersOfUrl}/`
      : '/';
    const date = new Date();
    date.setHours(23, 59, 59, 0);
    this.dateRange.endDate = date.toISOString();
    date.setMonth(date.getMonth() - 1);
    date.setHours(0, 0, 0, 0);
    // because of an analytics frontend bug set the min date to 2022-06-21
    this.minDate = new Date('2022-06-21T00:00:00').toISOString();
    const startDate = date.toISOString();
    if ((startDate < this.minDate) && !this.isSysAdmin) {
      this.dateRange.startDate = this.minDate;
    } else {
      this.dateRange.startDate = startDate;
      this.minDate = new Date('1995-12-17T03:24:00');
    }
  },
  apollo: {
    globalStats: {
      query: GetFathomData,
      variables() {
        return {
          obj: {
            aggregates: 'visits,pageviews,avg_duration,bounce_rate',
            filters: `[{"property":"pathname","operator":"is like","value":"${this.path}"},{"property":"hostname","operator":"is","value":"${this.selectedDomain}"}]`,
            date_from: this.dateRange.startDate,
            date_to: this.dateRange.endDate,
            timezone: this.timezone,
          },
        };
      },
      update(data) {
        this.queryErrors.globalStats = false;
        if (data.getFathomData.result) {
          const globalStats = data.getFathomData.result[0];
          const visitors = (globalStats.visits)
            ? globalStats.visits
            : '-';
          const views = (globalStats.pageviews)
            ? globalStats.pageviews
            : '-';
          const avgTime = (globalStats.avg_duration)
            ? globalStats.avg_duration
            : 0;
          const bounceRate = (globalStats.bounce_rate)
            ? `${Math.round(parseFloat(globalStats.bounce_rate) * 100)}%`
            : '-';
          return {
            visitors,
            views,
            avgTime,
            bounceRate,
          };
        }
        return null;
      },
      fetchPolicy: 'network-only',
      skip() {
        return !this.path || !this.selectedDomain;
      },
      error(error) {
        this.queryErrors.globalStats = true;
        console.log('Error globalStats', error);
      },
    },
    readsPerDayObject: {
      query: GetFathomData,
      variables() {
        return {
          obj: {
            aggregates: 'pageviews',
            date_grouping: 'day',
            filters: `[{"property":"pathname","operator":"is like","value":"${this.path}"},{"property":"hostname","operator":"is","value":"${this.selectedDomain}"}]`,
            date_from: this.dateRange.startDate,
            date_to: this.dateRange.endDate,
            timezone: this.timezone,
          },
        };
      },
      update(data) {
        this.queryErrors.readsPerDayObject = false;
        if (data.getFathomData.result) {
          const readsPerDay = data.getFathomData.result;
          // eslint-disable-next-line no-nested-ternary
          readsPerDay.sort((a, b) => ((a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0)));
          return readsPerDay.map((item) => ({
            label: item.date,
            value: item.pageviews,
          }));
        }
        return null;
      },
      fetchPolicy: 'network-only',
      skip() {
        return !this.path || !this.selectedDomain;
      },
      error(error) {
        this.queryErrors.readsPerDayObject = true;
        console.log('Error readsPerDayObject', error);
      },
    },
    referrers: {
      query: GetFathomData,
      variables() {
        return {
          obj: {
            aggregates: 'visits,pageviews,avg_duration,bounce_rate',
            filters: `[{"property":"pathname","operator":"is like","value":"${this.path}"},{"property":"hostname","operator":"is","value":"${this.selectedDomain}"}]`,
            field_grouping: 'referrer_hostname',
            sort_by: 'visits:desc',
            date_from: this.dateRange.startDate,
            date_to: this.dateRange.endDate,
            timezone: this.timezone,
          },
        };
      },
      update(data) {
        this.queryErrors.referrers = false;
        if (data.getFathomData.result) {
          const referrers = data.getFathomData.result;
          // eslint-disable-next-line no-nested-ternary
          referrers.sort((a, b) => ((a.date > b.date) ? 1 : ((b.date > a.date) ? -1 : 0)));
          return referrers.map((item) => ({
            name: (item.referrer_hostname) ? item.referrer_hostname : 'Direct/Unknown',
            visitors: item.visits,
            views: item.pageviews,
          }));
        }
        return null;
      },
      fetchPolicy: 'network-only',
      skip() {
        return !this.path || !this.selectedDomain;
      },
      error(error) {
        this.queryErrors.referrers = true;
        console.log('Error referrers', error);
      },
    },
    deviceTypes: {
      query: GetFathomData,
      variables() {
        return {
          obj: {
            aggregates: 'visits',
            filters: `[{"property":"pathname","operator":"is like","value":"${this.path}"},{"property":"hostname","operator":"is","value":"${this.selectedDomain}"}]`,
            field_grouping: 'device_type',
            sort_by: 'visits:desc',
            date_from: this.dateRange.startDate,
            date_to: this.dateRange.endDate,
            timezone: this.timezone,
          },
        };
      },
      update(data) {
        this.queryErrors.deviceTypes = false;
        if (data.getFathomData.result) {
          const deviceTypeOrig = data.getFathomData.result;
          const totalAmountOfVisitors = deviceTypeOrig.reduce(
            (a, b) => a + parseInt(b.visits, 10),
            0,
          );
          const deviceType = deviceTypeOrig.map((item) => ({
            name: item.device_type,
            visitors: `${Math.round(
              (parseInt(item.visits, 10) / totalAmountOfVisitors) * 100,
            )}%`,
          }));
          return deviceType;
        }
        return null;
      },
      fetchPolicy: 'network-only',
      skip() {
        return !this.path || !this.selectedDomain;
      },
      error(error) {
        this.queryErrors.deviceTypes = true;
        console.log('Error deviceTypes', error);
      },
    },
    countries: {
      query: GetFathomData,
      variables() {
        return {
          obj: {
            aggregates: 'visits',
            filters: `[{"property":"pathname","operator":"is like","value":"${this.path}"},{"property":"hostname","operator":"is","value":"${this.selectedDomain}"}]`,
            field_grouping: 'country_code',
            sort_by: 'visits:desc',
            date_from: this.dateRange.startDate,
            date_to: this.dateRange.endDate,
            timezone: this.timezone,
          },
        };
      },
      update(data) {
        this.queryErrors.countries = false;
        if (data.getFathomData.result) {
          const countryOrig = data.getFathomData.result;
          const totalAmountOfVisitorsByCountry = countryOrig.reduce(
            (a, b) => a + parseInt(b.visits, 10),
            0,
          );
          const countries = countryOrig.map((item) => {
            const percentageNumber = (
              parseInt(item.visits, 10) / totalAmountOfVisitorsByCountry
            ) * 100;
            return ({
              name: item.country_code,
              readers: Math.round(percentageNumber)
                ? `${Math.round(percentageNumber)}%`
                : `${percentageNumber.toFixed(2)}%`,
            });
          });
          console.log('countries', countries);
          return countries;
        }
        return null;
      },
      fetchPolicy: 'network-only',
      skip() {
        return !this.path || !this.selectedDomain;
      },
      error(error) {
        this.queryErrors.countries = true;
        console.log('Error countries', error);
      },
    },
  },
  filters: {
    timeSting(value) {
      if (!value) return '0';
      // eslint-disable-next-line no-param-reassign
      const totalSeconds = Math.floor(value);
      const seconds = totalSeconds % 60;
      const minutes = Math.floor(totalSeconds / 60);
      return (minutes > 0)
        ? `${minutes}min ${seconds}s`
        : `${seconds}s`;
    },
    inputDate(value) {
      if (!value) return value;
      const timestamp = new Date(value);
      if (!timestamp) {
        return value;
      }
      const month = timestamp.getMonth() + 1;
      const day = timestamp.getDate();
      return `${day}.${month}`;
    },
  },
  async mounted() {
    if (this.slugPathFromUrl === '#__ROOT_FOLDER__#') {
      this.slugPath = '';
    } else {
      this.slugPath = this.slugPathFromUrl;
    }
    this.getTheProjectAndFolderData();
    this.getThePageData();
  },
  methods: {
    getDataForSelectedDomain(domain) {
      this.selectedDomain = domain;
      this.runQueries();
    },
    updateValues(dateRange) {
      dateRange.startDate.setHours(0, 0, 0, 0);
      this.dateRange.start = dateRange.startDate.toISOString();
      dateRange.endDate.setHours(23, 59, 59, 0);
      this.dateRange.end = dateRange.endDate.toISOString();
      this.getTheProjectAndFolderData();
      this.getThePageData();
      this.$apollo.mutate({
        mutation: SetLoadingIndicator,
        variables: {
          isIndicatorLoading: true,
        },
      });
      this.runQueries();
    },
    runQueries() {
      this.$apollo.queries.globalStats.refetch();
      this.readsPerDayObject = null;
      this.$apollo.queries.readsPerDayObject.refetch();
      this.$apollo.queries.referrers.refetch();
      this.$apollo.queries.deviceTypes.refetch();
      this.$apollo.queries.countries.refetch();
    },
    getTheProjectAndFolderData() {
      this.$apollo.query({
        query: GetAllFoldersAndProjectsByFolderId,
        variables: {
          folderId: this.itemId,
        },
        fetchPolicy: 'network-only',
      }).then((result) => {
        // build the unique array for the async requests
        const childItems = [];
        result.data.folders.forEach((folder) => {
          childItems.push({
            name: folder.name,
            slug: folder.slug,
            order_index: folder.order_index,
            thumbnails: null,
            type: 'folder',
          });
        });
        result.data.projects.forEach((project) => {
          childItems.push({
            name: project.name,
            slug: project.slug,
            order_index: project.order_index,
            thumbnails: project.thumbnails,
            type: 'project',
          });
        });
        this.getTheFathomDataForChildFoldersAndProjects(childItems);
      }).catch((error) => {
        console.log('Error', error);
      });
    },
    getThePageData() {
      if (this.itemType === 'project') {
        this.$apollo.query({
          query: GetAllChildPageIdsOfProject,
          variables: {
            projectId: this.itemId,
          },
          fetchPolicy: 'network-only',
        }).then((result) => {
          if (result.data.projects.length) {
            this.getTheFathomDataForChildPages(result.data.projects[0].child_pages);
          }
        }).catch((error) => {
          console.log(error);
        });
      } else {
        this.$apollo.query({
          query: GetAllChildPageIdsOfFolder,
          variables: {
            folderId: this.itemId,
          },
          fetchPolicy: 'network-only',
        }).then((result) => {
          if (result.data.folders.length) {
            this.getTheFathomDataForChildPages(result.data.folders[0].child_pages);
          }
        }).catch((error) => {
          console.log(error);
        });
      }
    },
    getTheFathomDataForChildPages(childPages) {
      this.$apollo.query({
        query: GetFathomData,
        variables: {
          obj: {
            aggregates: 'visits,pageviews,uniques,avg_duration,bounce_rate',
            filters: `[{"property":"pathname","operator":"is like","value":"${this.path}"},{"property":"hostname","operator":"is","value":"${this.selectedDomain}"}]`,
            field_grouping: 'pathname',
            sort_by: 'pageviews:desc',
            date_from: this.dateRange.startDate,
            date_to: this.dateRange.endDate,
            timezone: this.timezone,
          },
        },
        fetchPolicy: 'network-only',
      }).then((result) => {
        if (result.data) {
          const allPages = [];
          const fathomDataForAllChildren = result.data.getFathomData.result;
          // we do the query for the global page views again because we don't know
          // when the page views are available in the global stats query
          this.$apollo.query({
            query: GetFathomData,
            variables: {
              obj: {
                aggregates: 'visits',
                filters: `[{"property":"pathname","operator":"is like","value":"${this.path}"},{"property":"hostname","operator":"is","value":"${this.selectedDomain}"}]`,
                date_from: this.dateRange.startDate,
                date_to: this.dateRange.endDate,
                timezone: this.timezone,
              },
            },
            fetchPolicy: 'network-only',
          }).then((pageVisitsObject) => {
            let globalPageViews = 0;
            if (pageVisitsObject.data.getFathomData.result.length) {
              globalPageViews = pageVisitsObject.data.getFathomData.result[0].visits;
            }
            childPages.forEach((childPage) => {
              const childSlugUrl = `${this.slugPath}/${childPage.slug}`;
              const imageObject = {
                imageUrl: this.getPageImageUrl(childPage.thumbnails),
                focalpoint: (childPage.thumbnails?.image?.focalpoint)
                  ? `${childPage.thumbnails.image.focalpoint.x}% ${childPage.thumbnails.image.focalpoint.y}%`
                  : null,
              };
              const [fathomChildPageData] = fathomDataForAllChildren.filter(
                (item) => item.pathname.startsWith(childSlugUrl),
              );
              // eslint-disable-next-line max-len
              const pageVisits = (fathomChildPageData) ? parseInt(fathomChildPageData.visits, 10) : 0;
              const entries = (globalPageViews)
                ? `${Math.floor((pageVisits / globalPageViews) * 100)}%`
                : '0%';
              allPages.push({
                name: childPage.name,
                entries,
                viewers: (fathomChildPageData) ? parseInt(fathomChildPageData.uniques, 10) : '0',
                views: (fathomChildPageData) ? parseInt(fathomChildPageData.pageviews, 10) : '0',
                avgReadingTime: (fathomChildPageData) ? Number(fathomChildPageData.avg_duration) : '0',
                bounceRate: (fathomChildPageData) ? `${Math.round(Number(fathomChildPageData.bounce_rate) * 100)}%` : '-',
                imageObject,
              });
            });
            this.pages = allPages;
            this.$apollo.mutate({
              mutation: SetLoadingIndicator,
              variables: {
                isIndicatorLoading: false,
              },
            });
          }).catch((error) => {
            console.log('Error: child pages', error);
            this.$apollo.mutate({
              mutation: SetLoadingIndicator,
              variables: {
                isIndicatorLoading: false,
              },
            });
          });
        }
      }).catch((error) => {
        console.log('Error: child pages', error);
        this.queryErrors.pages = true;
        this.$apollo.mutate({
          mutation: SetLoadingIndicator,
          variables: {
            isIndicatorLoading: false,
          },
        });
      });
    },
    getTheFathomDataForChildFoldersAndProjects(childItems) {
      // get only one data array
      Promise.all(childItems.map(
        (childItem) => {
          const childSlugUrl = `${this.slugPath}/${childItem.slug}/`;
          let imageUrl = null;
          if (childItem.type === 'project' && childItem.thumbnails?.cover_image?.url) {
            imageUrl = childItem.thumbnails.cover_image.url;
          }
          // do something like return new Promise
          return this.getChildItemData(
            childSlugUrl, childItem.name, childItem.type, imageUrl, childItem.order_index,
          );
        },
      )).then((responses) => {
        const allFoldersAndProjects = [];
        responses.forEach((response) => {
          if (response) {
            const item = {
              name: response.name,
              type: response.type,
              entries: (response.entries !== null) ? response.entries : 0,
              viewers: (response.viewers !== null) ? response.viewers : 0,
              views: (response.views !== null) ? response.views : 0,
              bounceRate: (response.bounceRate !== null) ? response.bounceRate : '-',
              avgReadingTime: (response.bounceRate !== null) ? response.avgReadingTime : 0,
              image: response.imageObject,
              order_index: response.order_index,
            };
            if (this.showReadersInTable) {
              item.readers = response.readers;
            }
            allFoldersAndProjects.push(item);
          }
        });
        // eslint-disable-next-line max-len,no-nested-ternary
        allFoldersAndProjects.sort((a, b) => ((a.order_index > b.order_index) ? 1 : ((b.order_index > a.order_index) ? -1 : 0)));
        this.projectOrFolders = allFoldersAndProjects;
      }).catch((error) => {
        console.log(error);
      });
    },
    getChildItemData(childSlugUrl, name, type, imageObject, order_index) {
      return new Promise((resolve) => {
        // do the query here!
        this.$apollo.query({
          query: GetFathomData,
          variables: {
            obj: {
              aggregates: 'visits,pageviews,uniques,avg_duration,bounce_rate',
              filters: `[{"property":"pathname","operator":"is like","value":"${childSlugUrl}"},{"property":"hostname","operator":"is","value":"${this.selectedDomain}"}]`,
              date_from: this.dateRange.startDate,
              date_to: this.dateRange.endDate,
              timezone: this.timezone,
            },
          },
          fetchPolicy: 'network-only',
        }).then((result) => {
          if (result) {
            const fathomData = result.data.getFathomData.result[0];
            console.log('fathomData.bounce_rate', fathomData.bounce_rate);
            const bounceRate = (fathomData.bounce_rate) ? `${Math.round(fathomData.bounce_rate * 100)}%` : null;
            resolve({
              name,
              imageObject,
              type,
              order_index,
              entries: fathomData.visits,
              viewers: fathomData.uniques,
              views: fathomData.pageviews,
              avgReadingTime: fathomData.avg_duration,
              bounceRate,
            });
          }
        }).catch((error) => {
          console.log('projectOrFolders error', error);
          this.queryErrors.projectOrFolders = true;
          resolve(null);
        });
      });
    },
    getPageImageUrl(thumbnail) {
      let cloudImageUrl = null;
      if (thumbnail?.image?.url) {
        cloudImageUrl = this.$makeCloudImage(
          thumbnail.image.url,
          {
            width: 210,
            height: 150,
          },
          thumbnail.image,
        );
      }
      return (thumbnail?.image?.url)
        ? `url(${cloudImageUrl})`
        // eslint-disable-next-line global-require
        : `url(${require('@/assets/thumb-default.png')})`;
    },
  },
  data() {
    return {
      queryErrors: {
        globalStats: false,
        readsPerDayObject: false,
        referrers: false,
        deviceTypes: false,
        countries: false,
        projectOrFolders: false,
        pages: false,
      },
      selectedDomain: null,
      defaultWebmagDomain: null,
      showDomainSelect: false,
      key: 0,
      readsPerDayObject: null,
      isBusyLoadingPages: true,
      client: null,
      path: null,
      enableQuery: false,
      timezone: 'Europe/Berlin',
      localeData: {
        direction: 'ltr',
        format: 'dd/mm/yyyy',
        separator: ' - ',
        applyLabel: this.$t('buttons.apply'),
        cancelLabel: this.$t('buttons.cancel'),
        weekLabel: 'W',
        customRangeLabel: 'Custom Range',
        daysOfWeek: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
        monthNames: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
        firstDay: 0,
      },
      dateRange: {
        startDate: null,
        endDate: null,
      },
      minDate: null,
      globalStats: null,
      folderOrProjectTableKey: 0,
      projectOrFolders: [],
      pageTableKey: 0,
      projectAndFolderFields: [
        {
          key: 'image',
          label: '',
          sortable: false,
          width: '50px',
          thClass: 'image',
          tdClass: 'image',
        },
        {
          key: 'name',
          label: 'Name',
          sortable: false,
          width: 'auto',
        },
        {
          key: 'entries',
          sortable: true,
          width: '80px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'views',
          sortable: true,
          width: '90px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'avgReadingTime',
          sortable: true,
          width: '140px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'bounceRate',
          sortable: true,
          width: '120px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      fields: [
        {
          key: 'image',
          label: '',
          sortable: false,
          width: '50px',
          thClass: 'image',
          tdClass: 'image',
        },
        {
          key: 'name',
          label: 'Name',
          sortable: false,
          width: 'auto',
        },
        {
          key: 'viewers',
          sortable: true,
          width: '90px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'views',
          sortable: true,
          width: '90px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'avgReadingTime',
          sortable: true,
          width: '140px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'bounceRate',
          sortable: true,
          width: '120px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'entries',
          sortable: true,
          width: '80px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      pages: [],
      fieldReferrers: [
        {
          key: 'name',
          label: 'Name',
          sortable: false,
          width: 'auto',
        },
        {
          key: 'visitors',
          sortable: true,
          width: '110px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
        {
          key: 'views',
          sortable: true,
          width: '110px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      referrers: null,
      fieldDeviceType: [
        {
          key: 'name',
          label: 'Name',
          sortable: false,
          width: 'auto',
        },
        {
          key: 'visitors',
          label: this.$t('settings.analytics.visitors'),
          sortable: true,
          width: '110px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      deviceTypes: [],
      fieldCountries: [
        {
          key: 'name',
          label: '',
          sortable: false,
          width: 'auto',
        },
        {
          key: 'readers',
          label: this.$t('settings.analytics.readers'),
          sortable: true,
          width: '110px',
          tdClass: 'text-center',
          thClass: 'text-center',
        },
      ],
      countries: [],
    };
  },
};
</script>

<style>
@media print {
  .tabs > div:first-child {
    display: none;
  }

  .table-dark {
    color: inherit;
    color: white;
  }

  .table {
    border-collapse: collapse !important;
    border-collapse: inherit !important;
  }

  .table td, .table th {
    background-color: inherit !important;
  }

  #line-chart {
    max-width: 100%;
  }

  .col-sm-10.offset-sm-1.col-12 p.mb-5 {
    display: none;
  }
}
</style>

<style scoped lang="scss">
/deep/ .daterangepicker .calendar-table td, /deep/ .daterangepicker .calendar-table th {
  border-radius: 0;
}

h3 {
  line-height: 38px;
}

.domain-select {
  max-width: 350px;

  select {
    width: 100%;
  }
}

h4, h5 {
  text-transform: uppercase;
  font-weight: 600;
}

h4 {
  color: #fff;
}

.global-stats {
  h5 {
    font-size: 13px;
    color: #b9b9b9;
  }

  div {
    font-size: 33px;
    font-weight: 400;
    color: #fff
  }
}

.table-dark {
  background-color: transparent;
}

/deep/ table {
  font-size: 15px;
  border-collapse: separate;
  border-spacing: 0 8px;

  thead th {
    text-transform: uppercase;
    font-size: 13px;

    //&:nth-child(2), &:nth-child(7) {
    //  text-align: center;
    //}
  }

  tbody tr {
    background-color: #53575B;
    box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);

    > td {
      padding: 7px 8px;

      &.image:first-child {
        padding: 0;
        max-width: 50px;
      }

      &.center {
        text-align: center;
        font-weight: 700;
      }

      &:nth-child(4) {
        ul {
          list-style: none;
          padding-left: 0;
          margin-bottom: 0;

          li {
            display: block;
            padding: 0 5px;
            margin-right: 6px;
            font-size: 13px;
            font-weight: 700;
            color: #44484C;
            background-color: #B9B9B9;
            text-transform: uppercase;
          }
        }
      }
    }
  }

  .cell-background-image {
    width: 100%;
    height: 100%;
    min-height: 36px;
    min-width: 50px;
    background-color: #777C7F;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
  }

  .cell-folder {
    background: rgba(83, 220, 134, 0.7);

    svg {
      fill: white;
    }
  }
}

hr {
  margin-top: 0;
}

.dropdown-icon {
  color: #A9B1B5;
  position: absolute;
  right: 10px;
  top: 10px;
}

/deep/ .reportrange-text {
  position: relative;
  background: #53575B;
  color: #B9B9B9;
  border: 1px solid #707070;
  padding: 10px 50px 2px 15px;
  line-height: 16px;
}

/deep/ #line-chart {
  min-height: 400px;
}

#content .query-error {
  font-family: 'Source Sans Pro', sans-serif;
  color: #fff;
  background-color: #53575B;
  border: 1px solid rgba(220, 83, 83, 0.7);
  box-shadow: 0 2px 3px 0 rgba(0, 0, 0, 0.16);
  font-size: 15px;
  padding: 7px 8px;
}

</style>
