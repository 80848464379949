var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "b-row",
        { staticClass: "row mb-4 mt-5" },
        [
          _c("b-col", { attrs: { cols: "12", "offset-sm": "1", sm: "10" } }, [
            _c(
              "div",
              { staticClass: "d-flex justify-content-between" },
              [
                _c("h3", [_vm._v(_vm._s(_vm.$t("settings.analytics.name")))]),
                _vm.showDomainSelect
                  ? _c(
                      "div",
                      { staticClass: "domain-select" },
                      [
                        _c("b-form-select", {
                          attrs: { options: _vm.domainSelectOptions },
                          on: { change: _vm.getDataForSelectedDomain },
                          model: {
                            value: _vm.selectedDomain,
                            callback: function($$v) {
                              _vm.selectedDomain = $$v
                            },
                            expression: "selectedDomain"
                          }
                        })
                      ],
                      1
                    )
                  : _vm._e(),
                _c("date-range-picker", {
                  ref: "picker",
                  attrs: {
                    opens: "left",
                    "locale-data": _vm.localeData,
                    minDate: _vm.minDate,
                    singleDatePicker: false,
                    showWeekNumbers: true,
                    showDropdowns: true,
                    autoApply: false
                  },
                  on: { update: _vm.updateValues },
                  scopedSlots: _vm._u([
                    {
                      key: "input",
                      fn: function(picker) {
                        return [
                          _c(
                            "div",
                            { staticStyle: { "min-width": "350px" } },
                            [
                              _vm._v(
                                " " +
                                  _vm._s(
                                    _vm._f("inputDate")(picker.startDate)
                                  ) +
                                  " - " +
                                  _vm._s(_vm._f("inputDate")(picker.endDate)) +
                                  " "
                              ),
                              _c("b-icon", {
                                staticClass: "pl-1 dropdown-icon",
                                attrs: {
                                  icon: "caret-down-fill",
                                  "aria-hidden": "true"
                                }
                              })
                            ],
                            1
                          )
                        ]
                      }
                    }
                  ]),
                  model: {
                    value: _vm.dateRange,
                    callback: function($$v) {
                      _vm.dateRange = $$v
                    },
                    expression: "dateRange"
                  }
                })
              ],
              1
            ),
            _c("hr"),
            _c("p", {
              staticClass: "mb-5",
              domProps: { innerHTML: _vm._s(_vm.$t("settings.analytics.desc")) }
            })
          ])
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { cols: "12", "offset-sm": "1", sm: "10" } },
            [
              _vm.queryErrors.globalStats
                ? _c(
                    "b-row",
                    { staticClass: "global-stats mb-5" },
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c("h4", [_vm._v("Global stats")]),
                        _c("div", { staticClass: "query-error" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("settings.analytics.queryError")) +
                              " "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm.globalStats
                ? _c(
                    "b-row",
                    { staticClass: "global-stats mb-5" },
                    [
                      _c("b-col", { attrs: { cols: "12", sm: "3" } }, [
                        _c(
                          "h5",
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("settings.analytics.visitors")) +
                                " "
                            ),
                            _c("b-icon", {
                              staticStyle: { "margin-left": "3px" },
                              attrs: {
                                id: "tooltip-global-visitors",
                                icon: "question-circle",
                                "aria-hidden": "true"
                              }
                            }),
                            _c(
                              "b-tooltip",
                              { attrs: { target: "tooltip-global-visitors" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "settings.analytics.visitorsTooltip"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("div", [_vm._v(_vm._s(_vm.globalStats.visitors))])
                      ]),
                      _c("b-col", { attrs: { cols: "12", sm: "3" } }, [
                        _c(
                          "h5",
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("settings.analytics.views")) +
                                " "
                            ),
                            _c("b-icon", {
                              staticStyle: { "margin-left": "3px" },
                              attrs: {
                                id: "tooltip-global-views",
                                icon: "question-circle",
                                "aria-hidden": "true"
                              }
                            }),
                            _c(
                              "b-tooltip",
                              { attrs: { target: "tooltip-global-views" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t("settings.analytics.viewsTooltip")
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("div", [_vm._v(_vm._s(_vm.globalStats.views))])
                      ]),
                      _c("b-col", { attrs: { cols: "12", sm: "3" } }, [
                        _c(
                          "h5",
                          [
                            _vm._v(
                              " " +
                                _vm._s(_vm.$t("settings.analytics.avgTime")) +
                                " "
                            ),
                            _c("b-icon", {
                              staticStyle: { "margin-left": "3px" },
                              attrs: {
                                id: "tooltip-global-avg-time",
                                icon: "question-circle",
                                "aria-hidden": "true"
                              }
                            }),
                            _c(
                              "b-tooltip",
                              { attrs: { target: "tooltip-global-avg-time" } },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "settings.analytics.avgTimeTooltip"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("div", [
                          _vm._v(
                            _vm._s(_vm._f("timeSting")(_vm.globalStats.avgTime))
                          )
                        ])
                      ]),
                      _c("b-col", { attrs: { cols: "12", sm: "3" } }, [
                        _c(
                          "h5",
                          { attrs: { id: "global-bounce-rate" } },
                          [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("settings.analytics.bounceRate")
                                ) +
                                " "
                            ),
                            _c("b-icon", {
                              staticStyle: { "margin-left": "3px" },
                              attrs: {
                                id: "tooltip-global-bounce-rate",
                                icon: "question-circle",
                                "aria-hidden": "true"
                              }
                            }),
                            _c(
                              "b-tooltip",
                              {
                                attrs: { target: "tooltip-global-bounce-rate" }
                              },
                              [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "settings.analytics.bounceRateTooltip"
                                      )
                                    ) +
                                    " "
                                )
                              ]
                            )
                          ],
                          1
                        ),
                        _c("div", [_vm._v(_vm._s(_vm.globalStats.bounceRate))])
                      ])
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                { staticClass: "mb-5" },
                [
                  _c(
                    "b-col",
                    [
                      _c("h4", [
                        _vm._v(_vm._s(_vm.$t("settings.analytics.readsPerDay")))
                      ]),
                      _vm.queryErrors.readsPerDayObject
                        ? _c("div", { staticClass: "query-error" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("settings.analytics.queryError")
                                ) +
                                " "
                            )
                          ])
                        : _vm.readsPerDayObject
                        ? _c("LineChart", {
                            key: _vm.key,
                            attrs: {
                              "reads-per-day-object": _vm.readsPerDayObject,
                              dateRange: _vm.dateRange
                            }
                          })
                        : _vm._e()
                    ],
                    1
                  )
                ],
                1
              ),
              _vm.queryErrors.projectOrFolders
                ? _c(
                    "b-row",
                    { staticClass: "mb-5" },
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c("h4", [
                          _vm._v(
                            _vm._s(
                              _vm.$t("settings.analytics.projectsOrFolders")
                            )
                          )
                        ]),
                        _c("div", { staticClass: "query-error" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("settings.analytics.queryError")) +
                              " "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm.itemType !== "project" && _vm.projectOrFolders.length
                ? _c(
                    "b-row",
                    { staticClass: "mb-5" },
                    [
                      _c(
                        "b-col",
                        [
                          _c("h4", [
                            _vm._v(
                              _vm._s(
                                _vm.$t("settings.analytics.projectsOrFolders")
                              )
                            )
                          ]),
                          _c("b-table", {
                            key: _vm.folderOrProjectTableKey,
                            attrs: {
                              fields: _vm.projectAndFolderFields,
                              items: _vm.projectOrFolders,
                              borderless: "",
                              dark: "",
                              fixed: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "table-colgroup",
                                fn: function(scope) {
                                  return _vm._l(scope.fields, function(field) {
                                    return _c("col", {
                                      key: field.key,
                                      style: { width: field.width }
                                    })
                                  })
                                }
                              },
                              {
                                key: "head(entries)",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-nowrap",
                                        attrs: {
                                          id: "tooltip-folder-project-entries"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "settings.analytics.visitors"
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "b-tooltip",
                                          {
                                            attrs: {
                                              target:
                                                "tooltip-folder-project-entries"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "settings.analytics.visitorsTooltip"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "head(viewers)",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-nowrap",
                                        attrs: {
                                          id: "tooltip-folder-project-viewers"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "settings.analytics.viewers"
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "b-tooltip",
                                          {
                                            attrs: {
                                              target:
                                                "tooltip-folder-project-viewers"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "settings.analytics.viewersTooltip"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "head(views)",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-nowrap",
                                        attrs: {
                                          id: "tooltip-folder-project-views"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("settings.analytics.views")
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "b-tooltip",
                                          {
                                            attrs: {
                                              target:
                                                "tooltip-folder-project-views"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "settings.analytics.viewsTooltip"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "head(avgReadingTime)",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-nowrap",
                                        attrs: {
                                          id:
                                            "tooltip-folder-project-avgReadingTime"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "settings.analytics.avgTime"
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "b-tooltip",
                                          {
                                            attrs: {
                                              target:
                                                "tooltip-folder-project-avgReadingTime"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "settings.analytics.avgTimeTooltip"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "head(bounceRate)",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-nowrap",
                                        attrs: {
                                          id:
                                            "tooltip-folder-project-bounceRate"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "settings.analytics.bounceRate"
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "b-tooltip",
                                          {
                                            attrs: {
                                              target:
                                                "tooltip-folder-project-bounceRate"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "settings.analytics.bounceRateTooltip"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "cell(image)",
                                fn: function(data) {
                                  return [
                                    data.item.type === "folder"
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "cell-folder cell-background-image d-flex justify-content-center align-items-center"
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: {
                                                icon: "folder",
                                                "aria-hidden": "true",
                                                scale: "1"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    data.item.type === "project" &&
                                    !data.item.image
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "cell-background-image d-flex justify-content-center align-items-center"
                                          },
                                          [
                                            _c("b-icon", {
                                              attrs: {
                                                icon: "files",
                                                "aria-hidden": "true",
                                                scale: "1"
                                              }
                                            })
                                          ],
                                          1
                                        )
                                      : _vm._e(),
                                    data.item.type === "project" &&
                                    data.item.image
                                      ? _c("div", {
                                          staticClass: "cell-background-image",
                                          style: `background-image: url(${data.item.image})`
                                        })
                                      : _vm._e()
                                  ]
                                }
                              },
                              {
                                key: "cell(avgReadingTime)",
                                fn: function(data) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("timeSting")(
                                            data.item.avgReadingTime
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _vm.queryErrors.pages
                ? _c(
                    "b-row",
                    { staticClass: "mb-5" },
                    [
                      _c("b-col", { attrs: { cols: "12" } }, [
                        _c("h4", [
                          _vm._v(_vm._s(_vm.$t("settings.analytics.pages")))
                        ]),
                        _c("div", { staticClass: "query-error" }, [
                          _vm._v(
                            " " +
                              _vm._s(_vm.$t("settings.analytics.queryError")) +
                              " "
                          )
                        ])
                      ])
                    ],
                    1
                  )
                : _vm.pages && _vm.pages.length
                ? _c(
                    "b-row",
                    { staticClass: "mb-5" },
                    [
                      _c(
                        "b-col",
                        [
                          _c("h4", [
                            _vm._v(_vm._s(_vm.$t("settings.analytics.pages")))
                          ]),
                          _c("b-table", {
                            key: _vm.pageTableKey,
                            attrs: {
                              fields: _vm.fields,
                              items: _vm.pages,
                              borderless: "",
                              dark: "",
                              fixed: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "table-colgroup",
                                fn: function(scope) {
                                  return _vm._l(scope.fields, function(field) {
                                    return _c("col", {
                                      key: field.key,
                                      style: { width: field.width }
                                    })
                                  })
                                }
                              },
                              {
                                key: "head(entries)",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-nowrap",
                                        attrs: { id: "tooltip-page-entries" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "settings.analytics.entries"
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "b-tooltip",
                                          {
                                            attrs: {
                                              target: "tooltip-page-entries"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "settings.analytics.entriesTooltip"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "head(viewers)",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-nowrap",
                                        attrs: { id: "tooltip-page-viewers" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "settings.analytics.pageViewers"
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "b-tooltip",
                                          {
                                            attrs: {
                                              target: "tooltip-page-viewers"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "settings.analytics.viewersTooltip"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "head(views)",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-nowrap",
                                        attrs: { id: "tooltip-page-views" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("settings.analytics.views")
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "b-tooltip",
                                          {
                                            attrs: {
                                              target: "tooltip-page-views"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "settings.analytics.viewsTooltip"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "head(avgReadingTime)",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-nowrap",
                                        attrs: {
                                          id: "tooltip-page-avgReadingTime"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "settings.analytics.avgTime"
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "b-tooltip",
                                          {
                                            attrs: {
                                              target:
                                                "tooltip-page-avgReadingTime"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "settings.analytics.avgTimeTooltip"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "head(bounceRate)",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-nowrap",
                                        attrs: { id: "tooltip-page-bounceRate" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "settings.analytics.bounceRate"
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "b-tooltip",
                                          {
                                            attrs: {
                                              target: "tooltip-page-bounceRate"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "settings.analytics.bounceRateTooltip"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "cell(image)",
                                fn: function(data) {
                                  return [
                                    _c("div", {
                                      staticClass: "cell-background-image",
                                      style: {
                                        backgroundImage:
                                          data.item.imageObject.imageUrl,
                                        backgroundPosition:
                                          data.item.imageObject.focalpoint
                                      }
                                    })
                                  ]
                                }
                              },
                              {
                                key: "cell(avgReadingTime)",
                                fn: function(data) {
                                  return [
                                    _vm._v(
                                      " " +
                                        _vm._s(
                                          _vm._f("timeSting")(
                                            data.item.avgReadingTime
                                          )
                                        ) +
                                        " "
                                    )
                                  ]
                                }
                              }
                            ])
                          })
                        ],
                        1
                      )
                    ],
                    1
                  )
                : _vm._e(),
              _c(
                "b-row",
                { staticClass: "mb-5" },
                [
                  _c(
                    "b-col",
                    [
                      _c("h4", [
                        _vm._v(_vm._s(_vm.$t("settings.analytics.referrers")))
                      ]),
                      _vm.queryErrors.referrers
                        ? _c("div", { staticClass: "query-error" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("settings.analytics.queryError")
                                ) +
                                " "
                            )
                          ])
                        : _c("b-table", {
                            attrs: {
                              fields: _vm.fieldReferrers,
                              items: _vm.referrers,
                              borderless: "",
                              "show-empty": "",
                              dark: "",
                              fixed: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "empty",
                                fn: function() {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("settings.analytics.noResult")
                                        )
                                      )
                                    ])
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "head(visitors)",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-nowrap",
                                        attrs: {
                                          id: "tooltip-referrers-visitors"
                                        }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t(
                                                "settings.analytics.visitors"
                                              )
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "b-tooltip",
                                          {
                                            attrs: {
                                              target:
                                                "tooltip-referrers-visitors"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "settings.analytics.visitorsReferrerTooltip"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              },
                              {
                                key: "head(views)",
                                fn: function() {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "text-nowrap",
                                        attrs: { id: "tooltip-referrers-views" }
                                      },
                                      [
                                        _vm._v(
                                          " " +
                                            _vm._s(
                                              _vm.$t("settings.analytics.views")
                                            ) +
                                            " "
                                        ),
                                        _c(
                                          "b-tooltip",
                                          {
                                            attrs: {
                                              target: "tooltip-referrers-views"
                                            }
                                          },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(
                                                  _vm.$t(
                                                    "settings.analytics.viewsReferrerTooltip"
                                                  )
                                                ) +
                                                " "
                                            )
                                          ]
                                        )
                                      ],
                                      1
                                    )
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                    ],
                    1
                  )
                ],
                1
              ),
              _c(
                "b-row",
                { staticClass: "mb-5" },
                [
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("h4", [
                        _vm._v(_vm._s(_vm.$t("settings.analytics.deviceType")))
                      ]),
                      _vm.queryErrors.deviceTypes
                        ? _c("div", { staticClass: "query-error" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("settings.analytics.queryError")
                                ) +
                                " "
                            )
                          ])
                        : _c("b-table", {
                            attrs: {
                              fields: _vm.fieldDeviceType,
                              items: _vm.deviceTypes,
                              borderless: "",
                              "show-empty": "",
                              dark: "",
                              fixed: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "empty",
                                fn: function() {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("settings.analytics.noResult")
                                        )
                                      )
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                    ],
                    1
                  ),
                  _c(
                    "b-col",
                    { attrs: { cols: "6" } },
                    [
                      _c("h4", [
                        _vm._v(_vm._s(_vm.$t("settings.analytics.countries")))
                      ]),
                      _vm.queryErrors.countries
                        ? _c("div", { staticClass: "query-error" }, [
                            _vm._v(
                              " " +
                                _vm._s(
                                  _vm.$t("settings.analytics.queryError")
                                ) +
                                " "
                            )
                          ])
                        : _c("b-table", {
                            attrs: {
                              fields: _vm.fieldCountries,
                              items: _vm.countries,
                              borderless: "",
                              "show-empty": "",
                              dark: "",
                              fixed: ""
                            },
                            scopedSlots: _vm._u([
                              {
                                key: "empty",
                                fn: function() {
                                  return [
                                    _c("div", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t("settings.analytics.noResult")
                                        )
                                      )
                                    ])
                                  ]
                                },
                                proxy: true
                              }
                            ])
                          })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }